import * as API from '@/services/API'
import { AxiosRequestConfig } from 'axios'

export default {
    getReclamations(phase: string) {
        return API.apiClient.get(`/reclamations/${phase}`)
    },
    postReclamation(puid: number, phase: string, params: any) {
        return API.apiClient.post(`/reclamations/${phase}/puid/${puid}`, params)
    },
    getReclamationPDFCandidat(phase: string, puid: number, index: number) {
        const params: AxiosRequestConfig = {
            headers: {
                Accept: 'application/pdf'
            },
            responseType: 'blob'
        }
        return API.apiClient.get(`/reclamations/${phase}/puid/${puid}/pdf_candidat/${index}`, params)
    },
    getDecisionPDF(puid: number, phase: string, epreuve_id: number) {
        const params: AxiosRequestConfig = {
            headers: {
                Accept: 'application/pdf'
            },
            responseType: 'blob'
        }
        return API.apiClient.get(`/reclamations/${phase}/puid/${puid}/pdf/${epreuve_id}`, params)
    }
}
