
import { Component, Vue } from 'vue-property-decorator'
import NavBarTop from '@/components/NavBarTop.vue'
import Loader from '@/components/Tools/Loader.vue'

import { mapState } from 'vuex'

@Component({
    computed: {
        ...mapState('user', ['user_select', 'loading_user', 'error_user']),
        ...mapState('reclamation', ['reclamation']),
        ...mapState('auth', ['to_url'])
    },
    components: {
        NavBarTop,
        Loader
    }
})
export default class App extends Vue {
    /**
     * @description Show menu
     */
    get showMenu() {
        const routes = [
            'login',
            'change-password',
            'reset-password',
            'forgot-password',
            'register',
            'conditions-utilisation',
            'verify-email'
        ]
        return !routes.includes(this.$store.state.auth.to_url)
    }

    created() {
        document.documentElement.style.setProperty('--env-color', process.env.VUE_APP_COLOR)

        if (
            !['identityservererror', 'unknownuser', 'login', 'reset-password', 'forgot-password', 'change-password', 'register', 'conditions-utilisation', 'assistance']
                .includes(this.$store.state.auth.to_url) &&
            this.$route.path !== '/version'
        ) {
            this.$store.dispatch('user/getUser')
                .catch(() => console.log({ e: this.$store.state.user.error_user }))
        }
    }
}
