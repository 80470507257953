import * as API from '@/services/API'

export default {
    async getParameters(): Promise<any> {
        return await API.apiClient.get(`/parameters`)
    },
    async getParameter(parameter_code: string): Promise<any> {
        return await API.apiClient.get(`/parameters/${parameter_code}`)
    }
}
