/*
 * This is the initial API interface
 * we set the base URL for the API
 */

import axios from 'axios'
import store from '@/store'

export const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL + '/api',
    xsrfCookieName: 'XSRF-TOKEN-CANDIDATS',
    withCredentials: true // required to handle the CSRF token
})

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: any) => {
        return response
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (error: any) {
        // console.log('error API')
        if (error.response && [401].includes(error.response.status)) {
            console.log('401')
            if (store.getters['auth/authUser']) {
                store.dispatch('auth/logout')
            }
            window.location.href = error.request.getResponseHeader('X-Redirect')
        }
        if (error.response && [419].includes(error.response.status) && store.getters['auth/authUser']) {
            // store.dispatch('auth/logout')
            console.log('419')
            console.log(error)
        }
        if (error.response && [422].includes(error.response.status)) {
            // store.dispatch('auth/logout')
            // console.log('422')
            // console.log(error)
        }
        if (error.response && [409].includes(error.response.status)) {
            // store.dispatch('auth/logout')
            // console.log('422')
            // console.log(error)
        }
        if (error.response && [500].includes(error.response.status)) {
            // store.dispatch('auth/logout')
            console.log('500')
            console.log(error)
        }
        return Promise.reject(error)
    }
)
