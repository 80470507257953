export enum BourseEmploiCode {
    POSTES = 'bourse_emploi_postes',
    FORM ='bourse_emploi_profil'
}

export function getIconBourseEmploiTab(value: BourseEmploiTab): string {
    switch (value) {
        case BourseEmploiTab.INFORMATIONS:
            return 'user'
        case BourseEmploiTab.OFFRES:
            return 'files'
        case BourseEmploiTab.CANDIDATURES:
            return 'file-alt'
    }
}

export enum BourseEmploiTab {
    INFORMATIONS = 'Informations',
    OFFRES = 'Offres d\'emploi',
    CANDIDATURES = 'Mes candidatures'
}

export enum JobStatus {
    DRAFT = 0,
    PUBLISHED = 1,
    PROVIDED = 2
}

export enum CandidacyStatus {
    PENDING = 0,
    REFUSED = 1,
    ACCEPTED = 2
}

export interface BourseEmploiInterface {
    code: string,
    datas: JobDescriptionInterface[],
    datas_candidat_input: CandidacyInterface[],
    group: string,
    message_code: string,
    name: string,
    puid: number
}

export interface JobDescriptionInterface {
    created_at: Date,
    datas: JobDescriptionDataInterface,
    id: number,
    media: JobDescriptionMediaInterface[],
    publication_id: number,
    unique_key: string,
    updated_at: Date,
    index?: number,
    start_at: Date,
    end_at: Date
}

export interface JobDescriptionDataInterface {
    contact: string,
    created_at: Date,
    description: string,
    id: number,
    missions: string,
    name: string,
    provided_at: string,
    published_at: string,
    required_profile: string,
    status: number,
    updated_at: Date,
    working_establishment: WorkingEstablismentInterface,
    working_establishment_id: number
}

export interface WorkingEstablismentInterface {
    adress: string,
    code_postal: string,
    created_at: Date,
    id: number,
    name: string,
    num_affiliation: string,
    session_id: number,
    updated_at: Date,
    ville: string
}

export interface JobDescriptionMediaInterface {
    id: number,
    model_type: string,
    model_id: number,
    uuid: string,
    collection_name: string,
    name: string,
    file_name: string,
    mime_type: string,
    disk: string,
    conversions_disk: string,
    size: number,
    manipulation: any[],
    custom_properties: {
        mime_type: string,
        size: number,
        crypted: boolean
    },
    generated_conversions: any[],
    responsive_images: any[],
    ordre_column: number,
    created_at: Date,
    updated_at: Date,
    original_url: string,
    preview_url: string
}

export interface CandidacyInterface {
    id: number,
    order: number,
    entity_id: number,
    status: CandidacyStatus
}
