import * as API from '@/services/API'

export default {
    getUser() {
        return API.apiClient.get('/user')
    },
    putUser(payload: any) {
        return API.apiClient.put('/user', payload)
    }
}
