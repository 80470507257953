import BourseEmploiService from '@/services/BourseEmploiService'
import { getError } from '@/utils/helpers'
import { BourseEmploiCode, BourseEmploiInterface, CandidacyStatus, JobDescriptionInterface } from "@/types/BourseEmploi";

export const namespaced = true

export const state: any = {
    bourseEmploi: [],
    error: null,
    error_access: null,
    loading: false
}

export const mutations = {
    RESET_STATE(state: any): void {
        Object.assign(state, {
            bourseEmploi: [],
            error: null,
            error_access: null,
            loading: false
        })
    },
    SET_BOURSE_EMPLOI(state: any, bourseEmploi: BourseEmploiInterface[]): void {
        state.bourseEmploi = bourseEmploi
    },
    SET_POSTES_DATAS_CANDIDAT_INPUT(state: any, datasCandidatInput: any): void {
        state.bourseEmploi.find((bourseEmploi: BourseEmploiInterface): boolean => bourseEmploi.code === BourseEmploiCode.POSTES)
            .datas_candidat_input = datasCandidatInput
    },
    SET_ERROR(state: any, error: any): void {
        state.error = error
    },
    SET_ERROR_ACCES(state: any, error: any): void {
        state.error_access = error
    },
    SET_LOADING(state: any, etat: boolean): void {
        state.loading = etat
    }
}

export const actions = {
    getBourseEmploi({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            BourseEmploiService.getBourseEmploi()
                .then((response: any): void => {
                    commit('SET_BOURSE_EMPLOI', response.data.data)
                    resolve(response)
                })
                .catch((error: any): void => {
                    // Dispatch les errors selon si erreur d'accès (403) ou erreur fonctionnelle
                    if (error?.response?.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getJobDocument({ commit }: { commit: any }, params: { publicationDataId: number; mediaUuid: string }) {
        return new Promise((resolve, reject): void => {
            BourseEmploiService.getJobDocument(params.publicationDataId, params.mediaUuid)
                .then((response: any): void => {
                    resolve(response)
                })
                .catch((error: any): void => {
                    // Dispatch les errors selon si erreur d'accès (403) ou erreur fonctionnelle
                    if (error?.response?.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
        })
    },
    updateCandidacy({ commit }: { commit: any }, params: { puid: number; payload: { id: number; status: CandidacyStatus }}) {
        return new Promise((resolve, reject): void => {
            BourseEmploiService.updateCandidacy(params.puid, params.payload)
                .then((response: any): void => {
                    commit('SET_POSTES_DATAS_CANDIDAT_INPUT', response.data.data)
                    resolve(response)
                })
                .catch((error: any): void => {
                    // Dispatch les errors selon si erreur d'accès (403) ou erreur fonctionnelle
                    if (error?.response?.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
        })
    },
    addCandidacy({ commit }: { commit: any }, params: { puid: number; jobId: any }) {
        return new Promise((resolve, reject): void => {
            BourseEmploiService.addCandidacy(params.puid, params.jobId)
                .then((response: any): void => {
                    commit('SET_POSTES_DATAS_CANDIDAT_INPUT', response.data.data)
                    resolve(response)
                })
                .catch((error: any): void => {
                    // Dispatch les errors selon si erreur d'accès (403) ou erreur fonctionnelle
                    if (error?.response?.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
        })
    },
    removeCandidacy({ commit }: { commit: any }, params: { puid: number; jobId: any }) {
        return new Promise((resolve, reject): void => {
            BourseEmploiService.removeCandidacy(params.puid, params.jobId)
                .then((response: any): void => {
                    commit('SET_POSTES_DATAS_CANDIDAT_INPUT', response.data.data)
                    resolve(response)
                })
                .catch((error: any): void => {
                    // Dispatch les errors selon si erreur d'accès (403) ou erreur fonctionnelle
                    if (error?.response?.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                })
        })
    }
}

export const getters = {
    bourseEmploi: (state: any): BourseEmploiInterface[] => {
        return state.bourseEmploi
    },
    postes: (state: any): JobDescriptionInterface[] => {
        return state.bourseEmploi
            .find((bourseEmploi: BourseEmploiInterface): boolean => bourseEmploi.code === BourseEmploiCode.POSTES) || {}
    },
    formulaire: (state: any): JobDescriptionInterface[] => {
        return state.bourseEmploi
            .find((bourseEmploi: BourseEmploiInterface): boolean => bourseEmploi.code === BourseEmploiCode.FORM) || {}
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    },
    error_access: (state: any): any => {
        return state.error_access
    }
}
