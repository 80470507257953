import * as API from '@/services/API'
import {CandidacyStatus} from "@/types/BourseEmploi";

export default {
    async getBourseEmploi(): Promise<any> {
        return await API.apiClient.get(`/job-market`)
    },
    async getJobDocument(publicationDataId: number, mediaUuid: string): Promise<any> {
        return await API.apiClient.get(`/candidacy/${publicationDataId}/media/${mediaUuid}?format=b64`)
    },
    async updateCandidacy(puid: number, payload: { id: number; status: CandidacyStatus }): Promise<any> {
        return await API.apiClient.post(`/candidacy/puid/${puid}`, { candidacies: payload })
    },
    async addCandidacy(puid: number, jobId: number): Promise<any> {
        return await API.apiClient.post(`/candidacy/puid/${puid}/add_candidacy`, { id: jobId })
    },
    async removeCandidacy(puid: number, jobId: number): Promise<any> {
        return await API.apiClient.delete(`/candidacy/puid/${puid}/remove_candidacy`, { data: { id: jobId } })
    }
}
