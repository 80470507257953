// Importer bootstrap avant les styles custom et le reste (sinon override lors du build)
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@/styles/global_style.scss'

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { IconPack, library } from '@fortawesome/fontawesome-svg-core'
// import { faAbacus, faWatch, faFileSpreadsheet, faPodium, faPencil, faArrowAltToBottom, faUserEdit, faUserClock, faPollPeople, faChevronRight, faBoxCheck, faLockOpen, faUser } from '@fortawesome/pro-solid-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas as IconPack, fal as IconPack)

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
