import ParameterService from '@/services/ParameterService'
import { getError } from '@/utils/helpers'
import { ParameterInterface } from "@/types/Parameter";

export const namespaced = true

export const state: ParameterInterface = {
    parameters: [],
    loading: false,
    error: null
}

export const mutations = {
    RESET_STATE(state: any): void {
        Object.assign(state, {
            parameters: [],
            loading: false,
            error: null
        })
    },
    SET_PARAMETERS(state: any, parameters: any): void {
        state.parameters = parameters
    },
    SET_LOADING(state: any, loading: boolean): void {
        state.loading = loading
    },
    SET_ERROR(state: any, error: any): void {
        state.error = error
    }
}

export const actions = {
    getParameters({ commit }: { commit: any }): Promise<any> {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            ParameterService.getParameters()
                .then((response): void => {
                    commit('SET_PARAMETERS', response.data.data)
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_PARAMETERS', [])
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getParameter({ commit }: { commit: any }, parameter_code: string): Promise<any> {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            ParameterService.getParameter(parameter_code)
                .then((response): void => {
                    commit('SET_ERROR', null)
                    resolve(response)
                })
                .catch((error): void => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
                .finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    parameters: (state: any): any => {
        return state.parameters
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    }
}
