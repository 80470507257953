import * as API from '@/services/API'

export default {
    getChoixPoste() {
        return API.apiClient.get(`/choix-poste-voeux`)
    },
    postChoixPoste(params: any) {
        return API.apiClient.post(`/choix-poste-voeux`, params)
    },
    choixPostePrioritaire(params: any) {
        return API.apiClient.post(`/choix-poste-prioritaire`, params)
    }
}
