
import { Component, Vue } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Menu from '@/components/Menu.vue'
import { mapState } from 'vuex'

@Component({
    computed: {
        ...mapState('user', ['user_select', 'error_user'])
    },
    components: {
        FontAwesomeIcon,
        Menu
    }
})
export default class NavBarTop extends Vue {
    /**
     * Déconnexion du candidat
     * @return {void}
     */
    logout(): void {
        this.$store.dispatch('auth/logout').then(() => {
            this.$store.commit('user/SET_USER_SELECT', null)
            window.location.href = this.$store.state.auth.back_url
        })
    }
}
