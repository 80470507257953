import axios from 'axios'

export const authClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    xsrfCookieName: 'XSRF-TOKEN-CANDIDATS',
    withCredentials: true // required to handle the CSRF token
})

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
    (response) => {
        return response
    },
    function (error) {
        return Promise.reject(error)
    }
)

export default {
    async login(payload: any) {
        await authClient.get('/sanctum/csrf-cookie')
        return authClient.post('/login', payload)
    },
    async forgotPassword(payload: any) {
        await authClient.get('/sanctum/csrf-cookie')
        return authClient.post('/forgot-password', payload)
    },
    async resetPassword(payload: any) {
        await authClient.get('/sanctum/csrf-cookie')
        return authClient.post('/reset-password', payload)
    },
    async register(payload: any) {
        await authClient.get('/sanctum/csrf-cookie')
        return authClient.post('/register', payload)
    },
    async sendEmailVerification() {
        await authClient.get('/sanctum/csrf-cookie')
        return authClient.post('/email/verification-notification')
    },
    logout() {
        return authClient.get('/logout')
    }
}
