import axios from 'axios'
import * as API from '@/services/API'

export const authClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    xsrfCookieName: 'XSRF-TOKEN-CANDIDATS',
    withCredentials: true // required to handle the CSRF token
})

export default {
    async sendMessage(payload: any) {
        await authClient.get('/sanctum/csrf-cookie')
        return API.apiClient.post('/assist', payload)
    }
}
