// eslint-disable @typescript-eslint/no-unused-vars

import FormulaireService from '@/services/FormulaireService'
import { dateIsBetween, dateIsSameOrAfter, dateNow, getError } from '@/utils/helpers'
import { Formulaire } from '@/types/Formulaire'

export const namespaced = true

export const state: any = {
    formulaires: [],
    formulaire: {},
    error: '',
    loading: false
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            formulaires: [],
            formulaire: {},
            error: '',
            loading: false
        })
    },
    SET_ERROR(state: any, error: any) {
        state.error = error
    },
    SET_LOADING(state: any, etat: boolean) {
        state.loading = etat
    },
    SET_FORMULAIRES(state: any, formulaires: Formulaire[]) {
        if (typeof formulaires === 'undefined') {
            formulaires = []
        }
        Object.freeze(formulaires)
        state.formulaires = formulaires
    },
    SET_FORMULAIRE(state: any, formulaire: any) {
        Object.freeze(formulaire)
        state.formulaire = formulaire
    }
}

export const actions = {
    getFormulaires({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject) => {
            FormulaireService.getFormulaires()
                .then((response) => {
                    commit('SET_FORMULAIRES', response.data.data)
                    commit('SET_ERROR', '')
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_FORMULAIRES', [])
                    commit('SET_ERROR', getError(error))
                    reject(error)
                }).finally(() => {
                    commit('SET_LOADING', false)
                })
        })
    },
    getFormulaire({ commit }: { commit: any }, params: { formulaire_id: number }) {
        return new Promise((resolve, reject) => {
            FormulaireService.getFormulaire(params.formulaire_id)
                .then((response) => {
                    commit('SET_FORMULAIRE', {
                        ...response.data,
                        id: parseInt(params.formulaire_id.toString())
                    })
                    commit('SET_ERROR', '')
                    resolve(response)
                })
                .catch((error) => {
                    commit('SET_FORMULAIRE', {})
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    get_formulaires: (state: any): Formulaire[] => {
        return [
            ...trieParFermeture(
                state.formulaires.filter((formulaire: Formulaire) => {
                    if (
                        !dateIsSameOrAfter(dateNow(), formulaire.end_at) &&
                        dateIsBetween(dateNow(), formulaire.visibility_start_at, formulaire.visibility_end_at, [])
                    ) {
                        return formulaire
                    }
                })
            ),
            ...trieParFermeture(
                state.formulaires.filter((formulaire: Formulaire) => {
                    if (dateIsSameOrAfter(dateNow(), formulaire.end_at)) {
                        return formulaire
                    }
                })
            )
        ]
    },
    get_formulaires_with_status(state: any): Formulaire[] {
        return state.formulaires.filter((formulaire: Formulaire) => {
            if (formulaire.publicationUserEtat !== null) {
                return formulaire
            }
        })
    },
    get_formulaire: (state: any): any => {
        return state.formulaire
    }
}

// Trie par date de fermeture du formulaire
function trieParFermeture(liste: Formulaire[]): Formulaire[] {
    return liste.sort((a: Formulaire, b: Formulaire) => {
        if (new Date(a.end_at) < new Date(b.end_at)) {
            return -1
        }
        if (new Date(a.end_at) > new Date(b.end_at)) {
            return 1
        }
        return 0
    })
}
