import { getError } from '@/utils/helpers'
import AuthService from '@/services/AuthService'

export const namespaced = true

export const state: any = {
    loading_auth: false,
    error_auth: null,
    back_url: '',
    to_url: '',
    temp_url: ''
}

export const mutations = {
    RESET_STATE(state: any) {
        Object.assign(state, {
            loading_auth: false,
            error_auth: null
        })
    },
    SET_LOADING(state: any, loading: boolean) {
        state.loading = loading
    },
    SET_ERROR(state: any, error: Array<string>) {
        state.error = error
    },
    SET_BACK_URL(state: any, url: string) {
        state.back_url = url
    },
    SET_TO_URL(state: any, url: string) {
        state.to_url = url
    },
    SET_TEMP_URL(state: any, url: string) {
        state.temp_url = url
    }
}

export const actions = {
    logout({ commit }: any) {
        console.log('logout')
        return new Promise((resolve, reject) => {
            AuthService.logout()
                .then((response: any) => {
                    commit('SET_BACK_URL', response.request.getResponseHeader('X-Redirect'))
                    resolve(response)
                })
                .catch((error: any) => {
                    commit('SET_ERROR', getError(error))
                    reject(error)
                })
        })
    }
}

export const getters = {
    error: (state: any) => {
        return state.error_auth
    },
    loading: (state: any) => {
        return state.loading_auth
    }
}
