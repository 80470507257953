import Vue from 'vue'
import Vuex from 'vuex'

import * as auth from '@/store/modules/Auth'
import * as user from '@/store/modules/User'
import * as reclamation from '@/store/modules/Reclamation'
import * as resultat from '@/store/modules/Resultat'
import * as convocation from '@/store/modules/Convocation'
import * as demission from '@/store/modules/Demission'
import * as support from '@/store/modules/Support'
import * as choixPoste from '@/store/modules/ChoixPoste'
import * as formulaire from '@/store/modules/Formulaire'
import * as parameter from '@/store/modules/Parameter'
import * as copie from '@/store/modules/Copie'
import * as bourseEmploi from '@/store/modules/BourseEmploi'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: false,
    actions: {
        reset(context) {
            context.commit('auth/RESET_STATE')
            context.commit('user/RESET_STATE')
            context.commit('reclamation/RESET_STATE')
            context.commit('resultat/RESET_STATE')
            context.commit('convocation/RESET_STATE')
            context.commit('demission/RESET_STATE')
            context.commit('support/RESET_STATE')
            context.commit('choixPoste/RESET_STATE')
            context.commit('formulaire/RESET_STATE')
            context.commit('parameter/RESET_STATE')
            context.commit('copie/RESET_STATE')
            context.commit('bourseEmploi/RESET_STATE')
        }
    },
    modules: {
        auth,
        user,
        reclamation,
        resultat,
        convocation,
        demission,
        support,
        choixPoste,
        formulaire,
        parameter,
        copie,
        bourseEmploi
    }
})

export default store
