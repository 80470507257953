import CopieService from '@/services/CopieService'
import { getError } from '@/utils/helpers'

export const namespaced = true

export const state: any = {
    copies: null,
    error: null,
    error_access: null,
    loading: false
}

export const mutations = {
    RESET_STATE(state: any): void {
        Object.assign(state, {
            copies: null,
            error: null,
            error_access: null,
            loading: false
        })
    },
    SET_COPIES(state: any, copies: any): void {
        state.copies = copies
    },
    SET_ERROR(state: any, error: any): void {
        state.error = error
    },
    SET_ERROR_ACCES(state: any, error: any): void {
        state.error_access = error
    },
    SET_LOADING(state: any, etat: boolean): void {
        state.loading = etat
    }
}

export const actions = {
    getCopies({ commit }: { commit: any }) {
        commit('SET_LOADING', true)
        return new Promise((resolve, reject): void => {
            CopieService.getCopies()
                .then((response: any): void => {
                    commit('SET_COPIES', response.data.data)
                    resolve(response)
                })
                .catch((error: any): void => {
                    // Dispatch les errors selon si erreur d'accès (403) ou erreur fonctionnelle
                    if (error?.response?.status === 403) {
                        commit('SET_ERROR_ACCES', getError(error))
                    } else {
                        commit('SET_ERROR', getError(error))
                    }
                    reject(error)
                }).finally((): void => {
                    commit('SET_LOADING', false)
                })
        })
    }
}

export const getters = {
    copies: (state: any): any => {
        return state.copies
    },
    loading: (state: any): boolean => {
        return state.loading
    },
    error: (state: any): any => {
        return state.error
    },
    error_access: (state: any): any => {
        return state.error_access
    }
}
