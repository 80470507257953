import * as API from '@/services/API'
import { AxiosRequestConfig } from 'axios'

export default {
    getConvocation(phase: string) {
        return API.apiClient.get(`/convocations/${phase}`)
    },
    getPlanningPDF(phase: string, puid: number) {
        const params: AxiosRequestConfig = {
            headers: {
                Accept: 'application/pdf'
            },
            responseType: 'blob'
        }
        return API.apiClient.get(`/convocations/${phase}/puid/${puid}/pdf`, params)
    },
    getDecisionAmenagementPDF(phase: string, puid: number) {
        const params: AxiosRequestConfig = {
            headers: {
                Accept: 'application/pdf'
            },
            responseType: 'blob'
        }
        return API.apiClient.get(`/convocations/${phase}/puid/${puid}/pdf`, params)
    }
}
