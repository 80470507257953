import moment from 'moment-timezone'

export const base64ToArrayBuffer = (base64: string) => {
    const binary_string = atob(base64)
    const len = binary_string.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
}

/**
 * Formate les erreurs pour renvoi interface
 * @param error
 * @returns
 */
export const getError = (error: any) => {
    let errorMessage = ''
    if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message
    }

    return errorMessage
}

/**
 * Formate les notes arrivées en number du serveur pour affichage en string
 * @param note
 */
export const format_note_to_show = (note: number) => {
    return note.toFixed(2).toString()
}

/**
 * Formate une date et heure pour affichage
 * @param date
 * @returns
 */
export const format_date_time = (date: Date | string): string | undefined => {
    if (date) {
        if (typeof date === 'string') {
            return moment(date).format('DD/MM/YYYY à HH:mm')
        }
        return moment(String(date)).format('DD/MM/YYYY à HH:mm')
    }
}

/**
 * Formate une date et heure pour affichage
 * @param date
 * @returns
 */
export const format_date_timeBis = (date: Date | string): string | undefined => {
    if (date) {
        if (typeof date === 'string') {
            return moment(date).format('DD/MM/YYYY à HH:mm')
        }
        return moment(String(date)).format('DD/MM/YYYY à HH[h]mm')
    }
}

/**
 * Formate une date pour affichage
 * @param date
 * @returns
 */
export const format_date = (date: Date | string): string | undefined => {
    if (date) {
        if (typeof date === 'string') {
            return moment(date).format('DD/MM/YYYY')
        }
        return moment(String(date)).format('DD/MM/YYYY')
    }
}

/**
 *
 * @param headers Retourne le nom d'un fichier télécharger du serveur
 * @returns
 */
export const getFileNameFromHeader = (headers: any) => {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    if (headers && headers['content-disposition']) {
        const matches = filenameRegex.exec(headers['content-disposition'])
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '')
        }
    }
    return null
}

/**
 * Retourne true si le mot de passe en entrée est robuste
 */
export const isPasswordStrong = (password: string) => {
    const length = password.length > 7
    const lower = password.search(/[a-z]/) !== -1
    const upper = password.search(/[A-Z]/) !== -1
    const digit = password.search(/[0-9]/) !== -1
    const symbol = password.search(/\p{Z}|\p{S}|\p{P}/u) !== -1
    // use same validation as portail-candidat-back 👆
    // https://github.com/illuminate/validation/blob/master/Rules/Password.php#L317
    return length && lower && upper && digit && symbol
}

/** Renvoie la date du jour */
export const dateDuJourFoDocument = (): string => {
    return moment().format('YYYYMMDD')
}

/** Date à l'instant T en valeur moment YYYY-MM-DDTHH:mm:ss[Z] */
export const dateNow = (): string => {
    return moment().utc().format('YYYY-MM-DDTHH:mm:ss.000000[Z]')
}

/** Ajoute n journée(s) à une date */
export const addDaysToDate = (date: any, n: number) => {
    return moment(date).add(n, 'day').toDate()
}

/** Retire n journée(s) à une date */
export const subtractDaysToDate = (date: any, n: number) => {
    return moment(date).subtract(n, 'day').toDate()
}

/** Retourne un boolean pour savoir si une date est entre deux autres dates */
export const dateIsBetween = (date: any, startDate: any, endDate: any, inclusion: any): boolean => {
    if (inclusion !== '..') {
        return moment(date).isBetween(startDate, endDate, undefined, inclusion)
    } else {
        return moment(date).isBetween(startDate, endDate)
    }
}

/** Check si une date est inférieure ou égale à une autre */
export const dateIsSameOrBefore = (date: any, dateVerif: any): boolean => {
    return moment(date).isSameOrBefore(dateVerif)
}

/** Check si une date est supérieure ou égale à une autre */
export const dateIsSameOrAfter = (date: any, dateVerif: any): boolean => {
    return moment(date).isSameOrAfter(dateVerif)
}
